// Dependency
import React from 'react';
import {Link} from "gatsby";
import Img from "gatsby-image";

// Components
import Tabs from "../../tabs/tabs";
import TrustBox from "../../trustpilot/trustbox";

// Service
import UtilsService from "../../../services/utils.service";

// Internationalization
import { useTranslation } from 'react-i18next';

// Styles
import * as styles from './compare-table.module.scss';
import * as mediaTabsStyles from "../../tabs/media-tabs.module.scss";
import * as ProductCardStyles from "../../cards/product-card.module.scss";

// Template
const ComparePageTable = ({data, centraProducts} ) => {

    // Translation Function
    const { t } = useTranslation();


    //Get Prices from API
    const prices = [];
    data.map( (p, index) => {
        let prodFilter = centraProducts.filter((prod) => {
            if (p.centraId.toString() === prod.centraProduct) prices[index] = prod.priceAsNumber !== prod.priceBeforeDiscountAsNumber ? `${prod.priceBeforeDiscount}|${prod.price}` : prod.price;
            return p.centraId.toString() === prod.centraProduct;
        })
    })

    return (
        <div>
            <Tabs >
                <div label={t('compare.battery')}>
                    <div className={`${mediaTabsStyles.cdMediaTabsGrid} ${styles.cdMobileCompare}`}>
                        { data && data.map( (element, a) => {
                            return  <div className={styles.cdMobileCompareItem}  key={a}>
                                <div className={styles.cdMobileCompareTop}>
                                    {
                                        data && <React.Fragment>
                                            <div className={styles.cdMobileCompareItemTop}>
                                                <Link to={`/${UtilsService.getLocaleFromUrl().country}/${t('slug.product')}/${element.slug}/`} className={`${styles.cdMobileCompareImg} cd-background-img`}>
                                                    <Img fluid={element.featuredImage.fluid} alt={''} />
                                                </Link>
                                                <div>
                                                    <Link to={`/${UtilsService.getLocaleFromUrl().country}/${t('slug.product')}/${element.slug}/`}>
                                                        <h3>{ element.title }</h3>
                                                    </Link>
                                                    <div className={`${styles.cdMobileComparePrice} cd-product-price`}>
                                                        {prices[a] && prices[a].includes("|") ? <><span className="cd-product-price-value">{prices[a].split('|')[1]}</span><div className={`${styles.cdMobileCompareOriginalPrice} cd-product-price-original`}>
                                                            <span className="cd-product-price-value">{prices[a].split('|')[0]}</span>
                                                        </div></> : <span className="cd-product-price-value"> {prices[a]}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                                <div className={styles.cdMobileCompareBottom}>
                                    <div className={styles.cdMobileCompareDesc}>
                                        <p>{element && element.batteryText ? element.batteryText : '-'}</p>
                                    </div>
                                    <div className={styles.cdMobileCompareTextItem}>
                                        <p><strong>{t('compare.batteryLife')}</strong></p>
                                        <p>{element && element.batteryTime ? element.batteryTime : '-'}</p>
                                    </div>
                                    <div className={styles.cdMobileCompareTextItem}>
                                        <p><strong>{t('compare.loadTime')}</strong></p>
                                        <p>{element && element.loadTime ? element.loadTime : '-'}</p>
                                    </div>
                                    <div className={styles.cdMobileCompareTextItem}>
                                        <p><strong>{t('compare.batteryType')}</strong></p>
                                        <p>{element && element.batteryType ? element.batteryType : '-'}</p>
                                    </div>
                                </div>
                            </div>
                        }) }
                    </div>
                </div>
                <div label={t('compare.performance')}>
                    <div className={`${mediaTabsStyles.cdMediaTabsGrid} ${styles.cdMobileCompare}`}>
                        { data && data.map( (element, a) => {
                            return  <div className={styles.cdMobileCompareItem}  key={a}>
                                <div className={styles.cdMobileCompareTop}>
                                    {
                                        data && <React.Fragment>
                                            <div className={styles.cdMobileCompareItemTop}>
                                                <Link to={`/${UtilsService.getLocaleFromUrl().country}/${t('slug.product')}/${element.slug}/`} className={`${styles.cdMobileCompareImg} cd-background-img`}>
                                                    <Img fluid={element.featuredImage.fluid} alt={''} />
                                                </Link>
                                                <div>
                                                    <Link to={`/${UtilsService.getLocaleFromUrl().country}/${t('slug.product')}/${element.slug}/`}>
                                                        <h3>{ element.title }</h3>
                                                    </Link>
                                                    <div className={`${styles.cdMobileComparePrice} cd-product-price`}>
                                                        {prices[a] && prices[a].includes("|") ? <><span className="cd-product-price-value">{prices[a].split('|')[1]}</span><div className={`${styles.cdMobileCompareOriginalPrice} cd-product-price-original`}>
                                                            <span className="cd-product-price-value">{prices[a].split('|')[0]}</span>
                                                        </div></> : <span className="cd-product-price-value"> {prices[a]}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                                <div className={styles.cdMobileCompareBottom}>
                                    <div className={styles.cdMobileCompareDesc}>
                                        <p>{element && element.performance ? element.performance : '-'}</p>
                                    </div>
                                    <div className={styles.cdMobileCompareTextItem}>
                                        <p><strong>{t('compare.stroke')}</strong></p>
                                        <p>{element && element.strokeLength ? element.strokeLength : '-'}</p>
                                    </div>
                                    <div className={styles.cdMobileCompareTextItem}>
                                        <p><strong>{t('compare.beats')}</strong></p>
                                        <p>{element && element.beatsPerMinute ? element.beatsPerMinute : '-'}</p>
                                    </div>
                                </div>
                            </div>
                        }) }
                    </div>
                </div>
                <div label={t('compare.motor')}>
                    <div className={`${mediaTabsStyles.cdMediaTabsGrid} ${styles.cdMobileCompare}`}>
                        { data && data.map( (element, a) => {
                            return  <div className={styles.cdMobileCompareItem}  key={a}>
                                <div className={styles.cdMobileCompareTop}>
                                    {
                                        data && <React.Fragment>
                                            <div className={styles.cdMobileCompareItemTop}>
                                                <Link to={`/${UtilsService.getLocaleFromUrl().country}/${t('slug.product')}/${element.slug}/`} className={`${styles.cdMobileCompareImg} cd-background-img`}>
                                                    <Img fluid={element.featuredImage.fluid} alt={''} />
                                                </Link>
                                                <div>
                                                    <Link to={`/${UtilsService.getLocaleFromUrl().country}/${t('slug.product')}/${element.slug}/`}>
                                                        <h3>{ element.title }</h3>
                                                    </Link>
                                                    <div className={`${styles.cdMobileComparePrice} cd-product-price`}>
                                                        {prices[a] && prices[a].includes("|") ? <><span className="cd-product-price-value">{prices[a].split('|')[1]}</span><div className={`${styles.cdMobileCompareOriginalPrice} cd-product-price-original`}>
                                                            <span className="cd-product-price-value">{prices[a].split('|')[0]}</span>
                                                        </div></> : <span className="cd-product-price-value"> {prices[a]}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                                <div className={styles.cdMobileCompareBottom}>
                                    <div className={styles.cdMobileCompareDesc}>
                                        <p>{element && element.motor ? element.motor : '-'}</p>
                                    </div>
                                    <div className={styles.cdMobileCompareTextItem}>
                                        <p><strong>{t('compare.noise')}</strong></p>
                                        <p>{element && element.noiseLevel ? element.noiseLevel : '-'}</p>
                                    </div>
                                    <div className={styles.cdMobileCompareTextItem}>
                                        <p><strong>{t('compare.pressure')}</strong></p>
                                        <p>{element && element.maximumPressure ? element.maximumPressure : '-'}</p>
                                    </div>
                                </div>
                            </div>
                        }) }
                    </div>
                </div>
                <div label={t('compare.spec')}>
                    <div className={`${mediaTabsStyles.cdMediaTabsGrid} ${styles.cdMobileCompare}`}>
                        { data && data.map( (element, a) => {
                            return  <div className={styles.cdMobileCompareItem}  key={a}>
                                <div className={styles.cdMobileCompareTop}>
                                    {
                                        data && <React.Fragment>
                                            <div className={styles.cdMobileCompareItemTop}>
                                                <Link to={`/${UtilsService.getLocaleFromUrl().country}/${t('slug.product')}/${element.slug}/`} className={`${styles.cdMobileCompareImg} cd-background-img`}>
                                                    <Img fluid={element.featuredImage.fluid} alt={''} />
                                                </Link>
                                                <div>
                                                    <Link to={`/${UtilsService.getLocaleFromUrl().country}/${t('slug.product')}/${element.slug}/`}>
                                                        <h3>{ element.title }</h3>
                                                    </Link>
                                                    <div className={`${styles.cdMobileComparePrice} cd-product-price`}>
                                                        {prices[a] && prices[a].includes("|") ? <><span className="cd-product-price-value">{prices[a].split('|')[1]}</span><div className={`${styles.cdMobileCompareOriginalPrice} cd-product-price-original`}>
                                                            <span className="cd-product-price-value">{prices[a].split('|')[0]}</span>
                                                        </div></> : <span className="cd-product-price-value"> {prices[a]}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                                <div className={styles.cdMobileCompareBottom}>
                                    <div className={styles.cdMobileCompareDesc}>
                                        <p>{element && element.specifications ? element.specifications : '-'}</p>
                                    </div>
                                    <div className={styles.cdMobileCompareTextItem}>
                                        <p><strong>{t('compare.weight')}</strong></p>
                                        <p>{element && element.weight ? element.weight : '-'}</p>
                                    </div>
                                    <div className={styles.cdMobileCompareTextItem}>
                                        <p><strong>{t('compare.size')}</strong></p>
                                        <p>{element && element.size ? element.size : '-'}</p>
                                    </div>
                                    <div className={styles.cdMobileCompareTextItem}>
                                        <p><strong>{t('compare.dimensions')}</strong></p>
                                        <p>{element && element.dimensions ? element.dimensions : '-'}</p>
                                    </div>
                                </div>
                            </div>
                        }) }
                    </div>
                </div>
            </Tabs>
        </div>
    );
};

export default ComparePageTable;