// Dependency
import React from 'react';
import {Link} from "gatsby";
import useMediaQuery from '../../hooks/useMediaQuery';

// Components
import ComparePageTable from "./compare-table/compare-page-table";

// Services
import UtilsService from "../../services/utils.service";

// Internationalization
import { useTranslation } from 'react-i18next';

// Styles
import * as styles from './compare.module.scss';
import * as mediaTabsStyles from "../tabs/media-tabs.module.scss";

// Template
const ComparePage = (props) => {


    // Translation Function
    const { t } = useTranslation();
    return (
        <div className={`${styles.compare} cd-dark-mode-black`}>
                <section className={`${mediaTabsStyles.cdMediaTabsSection} ${styles.cdCompareTabs} cd-media-tabs-section`} id="compare">
                    <div className="cd-max-width">
                        <h2>{t('compare.headLine')}</h2>
                        <ComparePageTable centraProducts={props.centraProducts} data={props.products} />
                    </div>
                </section>
        </div>
    );
};

export default ComparePage;