// Dependency
import React from "react"
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';

// Components
import AmbassadorCard from "../cards/ambassador-card";

// Styles
import * as ambassadorSwiperStyles from './ambassador-swiper.module.scss'
import 'swiper/scss';

// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

// Template
const AmbassadorSwiper = (props) => {
    return (
        <section className={ambassadorSwiperStyles.cdAmbassadorSection}>
            <div className="cd-max-width">
                {props.children ? <div className="cd-heading">{props.children}</div> : null}
                <div className="cd-swiper-wrapper">
                    <Swiper className={`cd-swiper cd-ambassador-swiper`}
                            spaceBetween={20}
                            slidesPerView={2}
                            slidesPerGroup={1}
                            loop={true}
                            grabCursor={true}
                            watchSlidesProgress={true}
                            pagination={{
                                dynamicBullets: true,
                                clickable: true
                            }}
                            navigation={ {
                                nextEl: '.cd-ambassador-next',
                                prevEl: '.cd-ambassador-prev'
                            }}
                            breakpoints={{
                                1100: {
                                    spaceBetween: 20,
                                    slidesPerView: 2,
                                    slidesPerGroup: 1
                                },
                                700:{
                                    spaceBetween: 12,
                                    slidesPerView: 2,
                                    slidesPerGroup: 1
                                },
                                0:{
                                    spaceBetween: 12,
                                    slidesPerView: 1,
                                    slidesPerGroup: 1
                                }
                            }}
                    >
                        {props.data.map( (ambassadorCard, index) => {
                            return (
                                <SwiperSlide key={index.toString()}>
                                    <AmbassadorCard data={ambassadorCard} />
                                </SwiperSlide>
                            )
                        } )}
                        <div className="cd-swiper-arrows cd-next-arrow cd-ambassador-next" ><div className="cd-arrow"></div></div>
                        <div className="cd-swiper-arrows cd-prev-arrow cd-ambassador-prev" ><div className="cd-arrow"></div></div>
                    </Swiper>
                </div>
            </div>
        </section>
    )
}

export default AmbassadorSwiper
