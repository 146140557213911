// extracted by mini-css-extract-plugin
export var cdBackgroundOverlay = "media-tabs-module--cd-background-overlay--bb957";
export var cdMediaTabPrev = "media-tabs-module--cd-media-tab-prev--e9f27";
export var cdMediaTabsArrows = "media-tabs-module--cd-media-tabs-arrows--91956";
export var cdMediaTabsBackgroundImg = "media-tabs-module--cd-media-tabs-background-img--955eb";
export var cdMediaTabsGrid = "media-tabs-module--cd-media-tabs-grid--0849f";
export var cdMediaTabsLeft = "media-tabs-module--cd-media-tabs-left--9348f";
export var cdMediaTabsMenu = "media-tabs-module--cd-media-tabs-menu--514b4";
export var cdMediaTabsMobile = "media-tabs-module--cd-media-tabs-mobile--981ab";
export var cdMediaTabsRight = "media-tabs-module--cd-media-tabs-right--a4d11";
export var cdMediaTabsSection = "media-tabs-module--cd-media-tabs-section--54391";
export var cdMediaTabsSectionMobile = "media-tabs-module--cd-media-tabs-section-mobile--b066c";