// extracted by mini-css-extract-plugin
export var cdMobileCompare = "compare-table-module--cd-mobile-compare--a8cab";
export var cdMobileCompareBottom = "compare-table-module--cd-mobile-compare-bottom--ca85e";
export var cdMobileCompareDesc = "compare-table-module--cd-mobile-compare-desc--b9607";
export var cdMobileCompareGrade = "compare-table-module--cd-mobile-compare-grade--3749b";
export var cdMobileCompareImg = "compare-table-module--cd-mobile-compare-img--b7152";
export var cdMobileCompareItem = "compare-table-module--cd-mobile-compare-item--353af";
export var cdMobileCompareItemTop = "compare-table-module--cd-mobile-compare-item-top--ee367";
export var cdMobileCompareOriginalPrice = "compare-table-module--cd-mobile-compare-original-price--1cf50";
export var cdMobileComparePrice = "compare-table-module--cd-mobile-compare-price--20e92";
export var cdMobileCompareTextItem = "compare-table-module--cd-mobile-compare-text-item--e30a1";
export var cdMobileCompareTop = "compare-table-module--cd-mobile-compare-top--8ca83";
export var compareTable = "compare-table-module--compareTable--e8578";