// Dependency
import React from "react";

// Components
import SupportCard from "../cards/support-card";

// Context
import ContentFlyinContext from '../modals/modal-flyin/ContentFlyinContext';

// Styles
import * as supportStyles from './support.module.scss'

// Template
const Support = (props) => {
    return (
        <ContentFlyinContext.Consumer>
        {(contentModalOpen) => <section className={`${supportStyles.cdSupportSection} ${props.cname}`}>
                <div className='cd-max-width'>
                    <div className="cd-heading">
                        {props.children}
                    </div>
                    <div className={supportStyles.cdSupportGrid}>
                        {props.data.map( (supportCard, index) => {
                            return (
                                <SupportCard
                                    data={supportCard}
                                    key={index.toString()}
                                    modalContext={contentModalOpen}
                                />
                                )
                            })
                        }
                    </div>
                </div>
            </section>
        }
        </ContentFlyinContext.Consumer>
        
    )
}

export default Support
