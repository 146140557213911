// Dependency
import React from "react"
import Img from "gatsby-image";
import { Link } from "gatsby";

// Styles
import * as faqCardStyles from './faq-card.module.scss'

// Services
import UtilsService from "../../services/utils.service";

// Internationalization
import { useTranslation } from 'react-i18next';

// Template
const FaqCard = (props) => {
    // Get locale since we are using relative URLs
    const locale = UtilsService.getLocaleFromUrl();
    const { t } = useTranslation();

    return (
        props.data.blogLink ? <Link to={`/${locale.country}/${t('slug.blog')}/${props.data.blogLink.categories[0].slug}/${props.data.blogLink.seoSlug}/`} className={`${faqCardStyles.cdFaqCard}`}>
            <div className={faqCardStyles.cdFaqCardContent}>
                <h3>{props.data.title}</h3>
                <p>{props.data.description}</p>
            </div>
            <div className={`${faqCardStyles.cdFaqCardImg} cd-background-img`}>
                <Img alt="" fluid={props.data.image.fluid} />
            </div>
        </Link> : null
    )
}

export default FaqCard
